import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero } from "../../containers"
import { Title } from "../../components"

const AdudevProjectPage = ({ data }) => {
  const image = getImage(
    data.strapiAdudevProjects.image.localFile.childImageSharp.gatsbyImageData
  )
  return (
    <section>
      <Hero image={image} title="AduDev Projects" />
      <div className="uk-container">
        <br />
        <br />
        <Title title={data.strapiAdudevProjects.title} />
        <h3>Presentation of All AduDev Projects</h3>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </section>
  )
}

export default AdudevProjectPage

export const query = graphql`
  {
    strapiAdudevProjects {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
    }
  }
`
